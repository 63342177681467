import { Paper, TableCell, TableContainer, TableBody, TableRow, Table, CircularProgress, Button, IconButton, Typography } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { UserModuleContext } from "../context/UserModuleContext";

export const AdInfoTable = () => {

    const [state, setState] = useState({ isLoaded: false, data: null, retry: false, error: null});

    const rows = [];

    const {authState} = useOktaAuth();

    const {userModule} = useContext(UserModuleContext);
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/ad/info`, {
            method: "POST",
            headers: {
                "Authorization": authState.accessToken.accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                "userId": userModule.userId
            })
        })
        .then(res => {
            return res.json();
        })
        .then(
          (result) => {
            setState((prev) => ({...prev, data: result, isLoaded: true}));
          }
        ).catch(error => {
            console.error(error);
            let timer = setTimeout(() => {
                fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/ad/info`, {
                    method: "POST",
                    headers: {
                        "Authorization": authState.accessToken.accessToken,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        "userId": userModule.userId
                    })
                })
                .then(res => {
                    return res.json();
                })
                .then(
                  (result) => {
                    setState((prev) => ({...prev, data: result, isLoaded: true}));
                  }
                ).catch(error => {
                    setState((prev) => ({...prev, isLoaded: true, error}));
                })
            }, 30000);
            return () =>{
                clearTimeout(timer);
            }
        })
    }, []);

    for(const key in state.data){
        if(key !== "statusCode"){
            let value = state.data[key];
            if(Array.isArray(value)){
                value = value.toString();
            }
            if(typeof value === "boolean"){
                value = value.toString();
            }
            rows.push(<TableRow key={key + "-row"}><TableCell key={key + "-cell"}>{key}</TableCell><TableCell key={key + "-value-" + state.data[key]}>{value}</TableCell></TableRow>);
        }
    }

    if(state.retry){
        return (<div id="container-div"><div className="progress-div" style={{display: "flex", justifyContent: "center", alignItems: "center"}}><p>Response is taking longer than anticipated.  Retrying...</p><CircularProgress style={{color: "#A60F2D" }} size={100} thickness={10}/></div></div>);        
    }else if(!state.isLoaded){
        return (<div id="container-div"><div className="progress-div" style={{display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress style={{color: "#A60F2D"}} size={100} thickness={10}/></div></div>);
    }else if(state.error){
        return (<div id="container-div">Error: {state.error.message}</div>);
    }else{
        return(
            <TableContainer component={Paper}>

                <Table sx={{ minWidth: 800}} aria-label="person-table">
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
        )
    }

}