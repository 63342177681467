import { forwardRef, Fragment, useContext, useState } from "react";
import { PersonTable } from "./PersonTable";
import { UserModuleContext } from "../context/UserModuleContext";
import { ModuleContext } from "../context/ModuleContext";
import { ModuleContainer } from "./ModuleContainer";
import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { OptionsMenu } from "./OptionsMenu";
import { AdInfoTable } from "./AdInfoTable";


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  export const UserContainer = () => {

    const {userModule, setUserModule} = useContext(UserModuleContext);

    const [module, setModule] = useState({isVisible: false, element: null, validated: false, validationError: null, userId: null, isLog: false});

    const handleClose = () => {
      setUserModule((prev) => ({...prev, isOpen: false, userId: null, wsuId: null, isLoaded: false, userData: null}));
      setModule((prev) => ({...prev, isVisible: false, element: null, validated: false, validationError: null, userId: null, isLog: false}));
    }
  
    return (
      <Fragment>
        <Dialog
          fullScreen={true}
          open={userModule.isOpen}
          TransitionComponent={Transition}
        >
        <ModuleContext.Provider value = {{module, setModule}}>
            <ModuleContainer />
            <AppBar sx={{ position: 'relative', backgroundColor: "#A60F2D", color: "white"}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {userModule.userId + " - " + userModule.wsuId}
            </Typography>           
          </Toolbar>
        </AppBar>
        <div>
        <div style={{width: "50%", display: "inline-block", float: "left", position: "relative"}}>
        <div className="container-div">
          <h3>Okta Information</h3>
          <hr/>
          <PersonTable identifier={userModule.userId} />
        </div>
        <div className="container-div">
          <h3>Active Directory Information</h3>
          <hr/>
          <AdInfoTable />
        </div>
        </div>
        { userModule.isLoaded && <OptionsMenu identifier={userModule.userData["Network ID"]} username={userModule.userData["Network ID"]} isRestricted={userModule.userData["Restricted From Modification in Tech Tools"]}/>} 
        </div>
        </ModuleContext.Provider>
        </Dialog>
      </Fragment>
    );
  }